.img-project{
    height: 650px;
    width: auto;
}
.project-img-cont{
    display: flex;
    justify-content: center;
  }
.main {
    position: relative;
    /* width: 50%; */
    display: flex;
    justify-content: center;
  }
.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #000;
   
  }
  .main:hover img {
    filter: blur(0.7px);
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
  }
  
  .main:hover .overlay {
    opacity: 0.8;
  }
  
  .text-12 {
    color: white;
    font-size: 25px;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
.bg{
 background-color: rgba(248, 249, 250, var(--bs-bg-opacity)) !important;
}
.main-img{
    height: 356px;
    width: 356px;
    transition: transform 5s ease-in-out;
}
.bg{
    background-color: #f8f9fa !important;
}
.DATA{
    max-width: 22rem;
}
.DATA2{
    background-color: rgba(0, 0, 0, 0.5)
}
.modal-header{
    border-bottom: none !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    margin-top: 10px !important;
    margin-right: 10px !important;
    padding: 0 !important;

}
.text-12{
    font-size: 1.125rem !important;
    font-weight: 600 !important;
}
.list{
    padding: 0;
}
.list > li{
    border-bottom: 1px solid #eaeaea;
    list-style-type: none;
    padding-bottom: 12px;
    padding-top: 12px;
}
.items{
    font-weight: 600 !important;
    margin-right: .5rem !important;
}
.btn-primary {
    background-color: rgb(255, 153, 0) !important;
    border-color: rgb(255, 153, 0) !important;
    color: #212529 !important;
}
.btn {
    border-width: 2px !important;
    font-weight: 500 !important;
    padding: .8rem 2.6rem !important;
    transition: all .5s ease !important;
}
.pr-cont{
    margin-bottom: 40px;
}


@media (min-width: 576px){
.modal-dialog {
    max-width: fit-content !important;
    margin-right: auto;
    margin-left: auto;
}
}

@media screen and (max-width: 768px){
    .img-project{
        height: 316px;
        width: 316px;
    }
  }