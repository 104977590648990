@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
#root,
body {
  height: 100%;
  margin: 0;
}
@media screen and (min-width: 600px) {
  body {
    font-size: 18px;
  }
  section {
    position: relative;
    padding: 6rem;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 599px) {
  body {
    font-size: 16px;
  }
  section {
    position: relative;
    padding: 1rem;
    padding-bottom: 0;
  }
}
body {
  margin: 0;
  padding: 0;
  background: white;
  color: black;
  font-family: 'Poppins', sans-serif;
  min-width: 320px;
  overflow-x: hidden;
}
button > span {
  font-family: 'Poppins', sans-serif;
}
.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}
section h1 {
  text-align: center;
}

@media screen and (min-width: 599px) {
  .profile {
    width: 180px !important;
    height: auto !important;
  }
}
@media screen and (max-width: 599px) {
  .profile {
    width: 100px;
    height: 100px;
  }
}
@media screen and (min-height: 599px) {
  .scroll-down {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 170px;
  }
}
@media screen and (max-height: 599px) and (min-height: 410px) {
  .scroll-down {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 100px;
  }
}
@media screen and (max-height: 409px) {
  .scroll-down {
    display: none;
  }
}
.home {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: calc(100vh + 63px);
  z-index: 1000;
}
.greeting {
  margin: -63px 20px 0 20px;
}
.hide {
  display: none;
}
.profile {
  border-radius: 50%;
  border: 0.2rem solid rgb(255, 153, 0);
}
.greeting-text {
  margin: 15px;
  font-size: 30px;
}
.greeting-text2{
  margin: 15px;
  font-size: 30px;
}
.name {
  color: rgb(250, 150, 0);
  font-weight: bold;
  font-size:35px;

}
.links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
  max-width: 60px;
  margin: 20px auto 0 auto;
}
.links a,
.links img {
  width: 50px;
  height: 50px;
}
.scroll-down-element {
  display: inline-block;
}
.wave-emoji {
  -webkit-animation-duration: 1.8s;
          animation-duration: 1.8s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: wave;
          animation-name: wave;
  display: inline-block;
  transform-origin: 70% 70%;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.particles {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh);
  width: 100%;
}
@-webkit-keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.navbar {
  position: absolute !important;
  bottom: 0px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #000000;
  border-bottom: 3px solid rgb(255, 153, 0);
  pointer-events: fill;
}
.navbar a {
  cursor: pointer;
  font-weight: 600;
  color: white;
  text-decoration: none;
}
.navlink-wrapper {
  width: 20%;
}
.navlinks {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  color: white;
}
#hamburger-icon {
  width: 35px;
  height: 21px;
  position: relative;
  margin: 0px auto;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
#hamburger-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
#hamburger-icon span:nth-child(1) {
  top: 0px;
}
#hamburger-icon span:nth-child(2),
#hamburger-icon span:nth-child(3) {
  top: 9px;
}
#hamburger-icon span:nth-child(4) {
  top: 18px;
}
#hamburger-icon.open span:nth-child(1) {
  top: 9px;
  width: 0%;
  left: 50%;
}
#hamburger-icon.open span:nth-child(2) {
  transform: rotate(45deg);
}
#hamburger-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
}
#hamburger-icon.open span:nth-child(4) {
  top: 9px;
  width: 0%;
  left: 50%;
}
@media screen and (min-width: 600px) {
  .profile {
    width: 120px;
  }
  .hamburger {
    display: none;
  }
  .mobilenav {
    display: none;
  }

}
@media screen and (max-width: 599px) {
  .navlinks {
    display: none;
  }
  .hamburger {
    position: absolute;
    right: 30px;
    cursor: pointer;
  }
}

.mobilenav-wrapper {
  position: absolute;
  top: 63px;
  width: 100%;
  clip: rect(0, 9999px, 9999px, -9999px);
}
.mobilenav {
  background-color: #1a212e;
}
.mobilenav.on {
  transition: transform ease-in-out 0.4s, opacity ease-out 0.3s;
  transform: translateY(0);
}
.mobilenav.off {
  transition: transform ease-in-out 0.4s, opacity ease-out 0.3s;
  transform: translateY(-100%);
}
.mobilenavlinks {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.mobilenavlink {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
}
.mobilenavlinks a {
  text-align: left;
  font-size: larger;
}

.about {
  background-color: white;
}
.about-text {
  width: 100%;
  max-width: 830px;
  font-size: 21px;
}
.inner-text1 {
  font-size: 9rem;
  margin-top: 0px;
}
.eight {
  display: inline-flex;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: rgb(255, 153, 0);
  align-items: center;
  justify-content: center;
  margin: 0;
  font-weight: 500 ;
  color: #212529;
}

.name-underline{
  border-bottom: 2px solid rgb(255, 153, 0);
  margin: 0 auto;
  margin-bottom: 15px;
  width: 70px;
  font-weight: bold;

}
.exp{
  font-weight: bold;
  color: black !important;
}

@media screen and (min-width: 960px) {
  .about-text-right {
    text-align: center;
  }
  .inner-text2 {
    font-size: 25px;
    margin-top: -10px;
    color: #212529
  }
  .hed-info{
    color: #6c757d !important;
    font-size: 15px;
  }
  .info{
    font-weight: bold;
    font-size: 16px;
    margin-top: -10px;
  }
  .info-container{
    padding-top: 30px;
  }
}
@media screen and (max-width: 959px) and (min-width: 600px) {
  .inner-text1 {
    font-size: 5rem;
    margin-top: 0px;
    text-align: center;
  }
  .eight {
    display: inline-flex;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: rgb(255, 153, 0);
    align-items: center;
    justify-content: center;
    margin: 0;
    font-weight: 500 ;
    color: #212529;
  }
  .inner-text2 {
    font-size: 28px;
    margin-top: 10px;
  }
  .parent{
  grid-template-columns: auto auto;
  }
  .hed-info{
    color: #6c757d !important;
    font-size: 15px;
  }
  .info{
    font-weight: bold;
    font-size: 16px;
    margin-top: -10px;
  }
  .info-container{
    padding-top: 30px;
  }
}
@media screen and (max-width: 599px) {
  .parent{
    grid-template-columns: auto auto;
    justify-content: center;
    }
  .info-wrapper {
    width: 50%; /* Set each info-wrapper to occupy half of the width */
  }
  .about-text{
    text-align: center;
  }
  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .inner-text1 {
    font-size: 5rem;
    margin-top: 0px;
    text-align: center;
    padding-bottom: 10px;

  }
  .eight {
    display: inline-flex;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: rgb(255, 153, 0);
    align-items: center;
    justify-content: center;
    margin: 0;
    font-weight: 500 ;
    color: #212529;
  }
  .info{
    font-weight: bold;
    font-size: 16px;
    margin-top: -10px;
  }
  .inner-text2 {
    font-size: 28px;
    margin-top: 10px;
  }
  .main-name {
    width: 100%;
    font-size: 27px;
  }
  .hed-info{
    color: #6c757d !important;
    font-size: 14px;
  }
  .info{
    font-weight: bold;
    font-size: 15px;
    margin-top: -10px;
    overflow-wrap: break-word;
  }
}

.section-title {
  font-size: 25pt;
  margin-bottom: 10px;
}
.underline {
  border-top: 4px solid rgb(255, 153, 0);
  margin: 0 auto;
  margin-bottom: 15px;
  width: 70px;
}

.head{
    text-align: center;
    padding-bottom: 20px;
}
.main{
    background-color: #F8F9FD !important;
}
.data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    margin: 10px;
    margin-top: 10px;
    padding-top: 45px;
    padding-bottom: 45px;
    box-shadow: 0px 5px 110px 10px rgba(0, 0, 0, 0.05);

}
.data h3{
    padding-top: 16px;
    font-size: 1.5rem;
    font-weight: 600;
}
.data p{
    font-size: 15px;
    padding-top: 10px;
    color: #6c757d!important;
    max-width: 350px;
}
.icon{
color: rgb(255, 153, 0);
font-size: 4.5rem;
}
.hero1{
    display: flex;
    justify-content: center;
}
.hero2{
    display: flex;
    justify-content: center;
}
.experience {
  position: relative;
  background-color: white;
}
.experience-content {
  margin: 0 auto;
  max-width: 1400px;
}
.experience-list {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  position: relative;
  padding-left: 0;
  list-style: none;
  margin: 0 auto;
}
.experience-list > li {
  border-radius: 5px;
  margin: 20px 10px;
  width: 390px;
  min-width: 290px;
}
.experience-list > li > div {
  height: 100%;
}

.textLink {
  color: #0be779;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .experience-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    padding-left: 0;
    list-style: none;
    margin: 0 auto;
  }
}
.experience-link {
  text-decoration: none;
  height: 100%;
  cursor: default;
}
.experience-card-wrapper {
  height: 100%;
  transition: 0.2s;
}
.experience-card {
  position: relative;
  background-color: #3b4353;
  border-radius: 5px;
  align-items: center;
  min-height: 158px;
  transition: 0.2s;
  height: 100%;
  cursor: pointer;
}
.experience-card-wrapper:hover .experience-card {
  transform: translateY(-10px);
  background-color: #474f63;
}
.experience-card-top {
  position: relative;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.experience-card-top > h2 {
  position: absolute;
  top: 30px;
  text-align: center;
  font-weight: 700;
  margin: 0 30px;
  font-size: 22px;
  color: white;
  text-decoration: none;
  z-index: 1;
}
.image-wrapper {
  position: absolute;
  bottom: -50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);
}
.experience-card-bottom {
  position: relative;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  height: calc(100% - 150px);
  color: white;
  padding: 65px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.experience-card-bottom h2 {
  margin: 0;
}
.experience-card-bottom h3 {
  margin: 20px 0;
  font-size: 18px;
}
.experience-card-bottom ul {
  list-style-type: disc;
  text-align: left;
}
.experience-card-bottom li {
  font-size: 16px;
}
.experience-card-tech {
  margin-top: 20px;
}
.experience-card-tech > ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: -7px;
  margin-left: -7px;
}
.experience-card-tech > ul > li {
  font-size: 12px;
  font-weight: 600;
  margin-top: 7px;
  margin-left: 7px;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: rgb(94, 106, 128);
}
.experience-bg {
  position: absolute;
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 100%;
}
.logo-bg {
  border-radius: 50%;
}
.company-logo {
  max-width: 150px;
  z-index: 1;
  pointer-events: none;
}
@media screen and (max-width: 599px) {
  .experience-card-wrapper:hover .experience-card {
    transform: translateY(0);
    background-color: #3b4353;
  }
  .experience-card-bottom {
    padding: 65px 15px 20px 15px;
  }
}

.skills .progress {
    height: 60px;
    display: block;
    background: none;
    border-radius: 0;
  }
  .skills .progress .skill {
    padding: 10px 0;
    margin: 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #45505B;
  }
  .skills .progress .skill .val {
    float: right;
    font-style: normal;
  }
  .skills .progress-bar-wrap {
    background: #F2F3F5;
    border-radius: 141px;
  }
  .skills .progress-bar {
    width: 1px;
    height: 8px;
    transition: 0.9s;
    border-radius: 141px;
    background-color: rgb(255, 153, 0);
  }
.img-project{
    height: 650px;
    width: auto;
}
.project-img-cont{
    display: flex;
    justify-content: center;
  }
.main {
    position: relative;
    /* width: 50%; */
    display: flex;
    justify-content: center;
  }
.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #000;
   
  }
  .main:hover img {
    -webkit-filter: blur(0.7px);
            filter: blur(0.7px);
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
  }
  
  .main:hover .overlay {
    opacity: 0.8;
  }
  
  .text-12 {
    color: white;
    font-size: 25px;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
.bg{
 background-color: rgba(248, 249, 250, var(--bs-bg-opacity)) !important;
}
.main-img{
    height: 356px;
    width: 356px;
    transition: transform 5s ease-in-out;
}
.bg{
    background-color: #f8f9fa !important;
}
.DATA{
    max-width: 22rem;
}
.DATA2{
    background-color: rgba(0, 0, 0, 0.5)
}
.modal-header{
    border-bottom: none !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    margin-top: 10px !important;
    margin-right: 10px !important;
    padding: 0 !important;

}
.text-12{
    font-size: 1.125rem !important;
    font-weight: 600 !important;
}
.list{
    padding: 0;
}
.list > li{
    border-bottom: 1px solid #eaeaea;
    list-style-type: none;
    padding-bottom: 12px;
    padding-top: 12px;
}
.items{
    font-weight: 600 !important;
    margin-right: .5rem !important;
}
.btn-primary {
    background-color: rgb(255, 153, 0) !important;
    border-color: rgb(255, 153, 0) !important;
    color: #212529 !important;
}
.btn {
    border-width: 2px !important;
    font-weight: 500 !important;
    padding: .8rem 2.6rem !important;
    transition: all .5s ease !important;
}
.pr-cont{
    margin-bottom: 40px;
}


@media (min-width: 576px){
.modal-dialog {
    max-width: -webkit-fit-content !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
    margin-right: auto;
    margin-left: auto;
}
}

@media screen and (max-width: 768px){
    .img-project{
        height: 316px;
        width: 316px;
    }
  }
.faq-accordion .accordion {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    border: none;
  }
  .faq-accordion .accordion__item {
    margin-bottom: 15px;
    border: none;
  }
  .faq-accordion .accordion__item:last-child {
    margin-bottom: 0;
  }
  .faq-img{
    text-align: center;
  }
 
  .faq-accordion .accordion__button {
    display: block;
    /* box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important; */
    background: #ffffff;
    padding: 20px 0px;
    color: #00000066;
    text-decoration: none;
    position: relative;
    display: block;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #00000066;
    border-radius: 0%;
    display: flex;
    align-items: center;

  }
  .faq-accordion .accordion__button:focus {
    outline: 0;
  }
  .faq-accordion .accordion__button i {
    position: absolute;
    right: 25px;
    top: 18px;
    font-size: 25px;
    transition: 0.5s;
  }
  .faq-accordion .accordion-title.active i {
    transform: rotate(180deg);
  }
  .faq-accordion .accordion__panel {
    background-color: white;
    position: relative;
    /* padding-bottom: 20px; */
    /* padding-right: 60px; */
    /* padding-left: 25px; */
    /* box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important; */
    
  }
  .faq-accordion .accordion__panel p {
    /* line-height: 1.8; */
    color: #212529;
    border-bottom: 1px solid #00000066;
    padding-bottom: 25px;
    position: relative;
    /* right: 12px; */
    left: 26px;
    top: 0;
    bottom: 0;

    

  }
  .faq-accordion .accordion-content.show {
    display: block;
  }
  .accordion__button[aria-expanded="true"] {
    color: #212529;
    border-bottom: none;
}
.accordion__button [aria-expanded="true"] .faq-accordion .accordion__panel p {
    color: #212529;
    border-bottom: none;
}
  
.IoIosArrowDown {
    transition: transform 0.8s ease;    
  }
  
  /* Rotate the icon when accordion button is expanded */
  .accordion__button[aria-expanded="true"] .IoIosArrowDown {
    transform: rotate(270deg); /* Rotate the icon 180 degrees */
  }

  .text{
    padding-left: 10px;
  }

  @media screen and (max-width: 768px){
    .faq-img img{
      height: 351px;
      width: 341px;
    }
  }
  @media screen and (max-width: 465px){

    .faq-accordion .accordion__panel p {
        /* line-height: 1.8; */
        color: #212529;
        border-bottom: 1px solid #00000066;
        padding-bottom: 25px;
        position: relative;
        /* right: 12px; */
        left: 20px;
        top: 0;
        bottom: 0;
    
      }
  }
.testmonial_section-three-main-div__ffDcr {
    display: grid;
    grid-template-rows: 1fr 50px;
    justify-items: center;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    /* background-color: rgb(250, 251, 253); */
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
  }
  
  .testmonial_quotes-img-right__25Ir8 {
    justify-self: end;
    /* background-image: url(../../public/left.png); */
    margin-right: 20px;
  }
  
  .testmonial_quotes-img-left__3ICjS {
    /* background-image: url(./right.png); */
    margin-left: 20px;
  }
  
  .testmonial_section-three-sub-div-one__5CbHP {
    display: grid;
    grid-template-columns: 10% 1fr 10%;
    justify-content: center;
    text-align: center;
    margin-top: 2rem;
  }
  
  .testmonial_main-quotes-div__LsHuZ {
    /* width: 1110px; */
    transition: 1s;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
  
  .testmonial_quotes-img__1xxnm {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 25px;
    height: 23px;
  }
  
  /* ------------------ */
  .testmonial_para__1ZPgS {
    font-family: "Lato", sans-serif;
    font-size: 1.3125rem !important;
    color: white;
  }
  
  .testmonial_subText__1Hrp- {
    margin-top: 1rem;
    font-size: 1rem;
    color: white;
    font-weight: 600;
  }
  
  .testmonial_myDot__2wl2x {
    display: inline-block;
    cursor: pointer;
    border: none;
    padding: 5.5px;
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 5px;
    background: rgb(130, 126, 119);
  }
  
  .testmonial_myDot--active__38RDV {
    background: rgb(255, 153, 0);
    border: 1.5px solid black;
  }
  
  .testmonial_not-visible__uj8Mi {
    opacity: 0;
    transition: 1s;
  }
  
  @media (max-width: 767px) {
  
    .testmonial_section-three-sub-div-one__5CbHP {
      grid-template-columns: 15% 1fr 15%;
    }
  }
.test-img{
    width: 70px;
    height: 70px;
    margin-top: 10px;
    border:3px solid  rgb(255, 153, 0);
    border-radius: 50%;
}
.test-bg{
    background-color: #939597!important;
    margin-top: 90px;
}
.test-desigination{
   color: #dee3e4 !important;
   font-size: 0.9rem;
   padding-top: 4px;
}
.contact {
  background-color:white;
}
.links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
  max-width: 600px;
  margin: 50px auto 0 auto;
}
.links a, .links img {
  width: 40px;
  height: 40px;
  color: black;
}

contact .info {
  width: 100%;
}
.thank-you-message{
  text-align: center;
}

.contact .info .i {
  font-size: 16px;
  color: rgb(255, 176, 57);
  float: left;
  width: 44px;
  height: 44px;
  /* background: #EEF7FF; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  /* color: #45505B; */
}
.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #728394;
}
.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}
.contact .info .email:hover .i,
.contact .info .address:hover .i,
.contact .info .phone:hover .i {
  background: rgb(255, 176, 57);
  transition: all .6s ease-in-out;
  color: white;
  padding: 5px;
}
.contact .email-form {
  width: 100%;
  /* background: #fff; */
}
.contact .email-form .form-group {
  padding-bottom: 8px;
}
.contact .email-form .error-message {
  display: none;
  color: #fff;
  background: #ED3C0D;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}
.contact .email-form .error-message br+br {
  margin-top: 25px;
}
.contact .email-form .sent-message {
  display: none;
  color: #fff;
  background: #18D26E;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.contact .email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.contact .email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18D26E;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
          animation: animate-loading 1s linear infinite;
}
.contact .email-form input,
.contact .email-form textarea {
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
}
.contact .email-form input[type=text],
.contact .email-form input[type=email],
.contact .email-form input[type=tel] {
  height: 44px;
}
.contact .email-form textarea {
  padding: 10px 12px;
}
.contact .email-form button[type=submit] {
  background: rgb(255, 176, 57);
  border: 0;
  padding: 10px 35px;
  color: black;
  transition: 0.4s;
  border-radius: 50px;
}
.contact .email-form button[type=submit]:hover {
  background: rgb(255, 153, 0);
}
@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


footer {
  background-color: white;
  padding-bottom: 10px;
  margin-top: 20px;

}
#footer {
background: #f7f8f9;
  font-size: 14px;
  text-align: center;
  padding-top: 40px;
  
}
.cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.cont > h3{
  font-weight: 500;
  font-size: 36px;
}
.pic {
  margin-bottom: 25px;
}

#footer h3 {
  color: black;
  font-size: 36px;
  font-weight: 700;
  position: relative;
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 20px 0 20px 0;
}

#footer p {
  color: black;
  font-size: 15;
  padding: 0;
  margin: 0 0 20px 0;
}
#footer .social-links {
  margin: 0 0 20px 0;
}
.icon-1{
  font-size: 35px;
  color: black;
  margin: 10px;
}
.icon-1:hover{
  color: rgb(255, 153, 0);
  transition: all .5s ease-in-out;
}
/* #footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: black;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
} */
/* #footer .social-links a:hover {
  background: rgb(255, 153, 0);
  color: black;
  text-decoration: none;
} */
#footer .copyright {
  margin: 0 0 5px 0;
}
#footer .credits {
  font-size: 13px;
}
.designed {
      text-align: center;
      color: #fff;
      i {
        position: relative;
        top: 0px;
        color: orangered;
        -webkit-animation: heartBeat 1000ms infinite linear;
                animation: heartBeat 1000ms infinite linear;
      }
      a {
        color: orangered;
      }
    }

.topButton {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, .2);
  -webkit-filter: blur(0.7px);
          filter: blur(0.7px);
  color: white;
  cursor: pointer;
  padding: 6px;
  border-radius: 15px;
  font-size: 25px;
  /* box-shadow: 0 5px 15px rgba(0,0,0,.15); */
  transition: all .3s ease-in-out;

}
button.topButton.on:hover {
  background-color: rgb(255, 153, 0);
  transition: all .4s ease-in-out;
  color:black

}
.topButton i {
  display: flex;
}
.topButton.on {
  opacity: 1;
  transition: opacity 0.1s linear;
}
.topButton.off {
  opacity: 0;
  transition: opacity 0.1s linear;
}

